import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from "firebase/app";
import ReactGA from "react-ga4";

const firebaseConfig = {
    apiKey: "AIzaSyCN2T7a6bW3NJp19EXb_G5lWhwpte0-N6g",
    authDomain: "here-we-go-78cc9.firebaseapp.com",
    projectId: "here-we-go-78cc9",
    storageBucket: "here-we-go-78cc9.appspot.com",
    messagingSenderId: "817793070279",
    appId: "1:817793070279:web:390eec5b148ba890fffae2",
    measurementId: "G-G1TFV03PDF"
  };
  
// Initialize Firebase
initializeApp(firebaseConfig);

ReactGA.initialize("G-SW2MKCH4M3");
ReactGA.send({ hitType: "pageview", page: "/" });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
