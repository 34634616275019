import PropTypes from "prop-types";

export default function InfoCard(props) {

  return (
    <div className="block w-80 md:w-auto py-4 px-8 bg-white rounded-lg border border-gray-200 shadow-md">
      {props.children} 
    </div>
  )
}
InfoCard.propTypes = {
  children: PropTypes.node.isRequired
}