import InfoCard from "../Components/InfoCard";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import GlobalContext from "../store/global-context";
import { IconContext } from "react-icons";
import {
    BsPersonFill,
    BsFillArrowRightCircleFill,
    BsArrowRightShort,
    BsArrowLeftShort,
} from "react-icons/bs";

export default function ClubTransferCard(props) {
    const ctx = useContext(GlobalContext);

    // Pagination control
    const PAGE_SIZE = 3;
    const [offset, setOffset] = useState(0);

    var playersTransfered;

    const edges = props.edges;

    // List all the players that transfer in and out
    if (!!ctx.selectedClub) {
        const playersLeftEdges = edges.filter(
            (d) => d.club_left_name === ctx.selectedClub
        );
        const playersJoinedEdges = edges.filter(
            (d) => d.club_joined_name === ctx.selectedClub
        );
        playersTransfered = playersJoinedEdges.concat(playersLeftEdges);
    }

    return (
        <InfoCard>
            <h2 className="m-0 p-0 py-2 whitespace-nowrap font-bold text-slate-600">
                {!!ctx.selectedClub ? ctx.selectedClub : "Select a club"}
            </h2>
            <ul>
                {!!playersTransfered &&
                    playersTransfered
                        .slice(
                            PAGE_SIZE * offset,
                            PAGE_SIZE * offset + PAGE_SIZE
                        )
                        .map((d) => (
                            <li key={d.name} className="my-2">
                                <div className="flex flex-row items-center space-x-3">
                                    <div className="flex items-center justify-center rounded-full bg-slate-600 w-8 h-8 m-0 p-0 ">
                                        <IconContext.Provider
                                            value={{
                                                color: "white",
                                                size: "24px",
                                            }}
                                        >
                                            <div>
                                                <BsPersonFill />
                                            </div>
                                        </IconContext.Provider>
                                    </div>
                                    <div>
                                        <h3 className="font-bold ml-3">{d.name} - (
                                          {d.loan_transfer === "True" ? "Loan " : ""}
                                          €{d.transfer_fee > 0 ? d.transfer_fee / 1e6 + "m" : "0"}
                                        )</h3>
                                        <p className="flex flex-row justify-items-start items-center space-x-3">
                                            <span
                                                className={`hidden md:inline md:text-s ml-3
                                             ${
                                                 d.club_left_name ===
                                                 ctx.selectedClub
                                                     ? "text-slate-600"
                                                     : "text-slate-300"
                                             }`}
                                            >
                                                {d.club_left_name}
                                            </span>
                                            <span
                                                className={`text-xs md:hidden m-0
                                             ${
                                                 d.club_left_name ===
                                                 ctx.selectedClub
                                                     ? "text-slate-600"
                                                     : "text-slate-300"
                                             }`}
                                            >
                                                {ctx.clubMapping.get(d.club_left_name)[0].abbrev}
                                            </span>
                                            <IconContext.Provider
                                                value={{
                                                    color:
                                                        d.club_left_name ===
                                                        ctx.selectedClub
                                                            ? "green"
                                                            : "red",
                                                    size: "20px",
                                                }}
                                            >
                                                <BsFillArrowRightCircleFill />
                                            </IconContext.Provider>
                                            <span
                                                className={`hidden md:inline md:text-s
                                             ${
                                                 d.club_joined_name ===
                                                 ctx.selectedClub
                                                     ? "text-slate-600"
                                                     : "text-slate-300"
                                             }`}
                                            >
                                                {d.club_joined_name}
                                            </span>
                                            <span
                                                className={`text-xs md:hidden m-0
                                             ${
                                                 d.club_joined_name ===
                                                 ctx.selectedClub
                                                     ? "text-slate-600"
                                                     : "text-slate-300"
                                             }`}
                                            >
                                                {ctx.clubMapping.get(d.club_joined_name)[0].abbrev}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </li>
                        ))}
            </ul>
            {/* Pagination */}
            <div className="flex flex-row justify-center space-x-3">
                <button 
                  className="flex items-center justify-center rounded-full bg-slate-600 w-4 h-4 m-0 p-0 hover:bg-slate-800 focus:ring-2 focus:outline-none focus:ring-blue-300 disabled:bg-slate-100"
                  onClick={() => {
                    if (offset !== 0) {
                      setOffset(offset - 1)
                    }
                  }}
                  disabled={!!playersTransfered && offset === 0}
                  >
                    <IconContext.Provider
                        value={{ color: "white", size: "16px" }}
                    >
                        <div>
                            <BsArrowLeftShort />
                        </div>
                    </IconContext.Provider>
                </button>

                <button 
                  className="flex items-center justify-center rounded-full bg-slate-600 w-4 h-4 m-0 p-0 hover:bg-slate-800 focus:ring-2 focus:outline-none focus:ring-blue-300 disabled:bg-slate-100"
                  onClick={() => {
                    if (offset !== Math.floor(playersTransfered.length / PAGE_SIZE)) {
                      setOffset(offset + 1)
                    }
                  }}
                  disabled={!!playersTransfered && offset === Math.floor(playersTransfered.length / PAGE_SIZE) - 1}
                >
                    <IconContext.Provider
                        value={{ color: "white", size: "16px" }}
                    >
                        <div>
                            <BsArrowRightShort />
                        </div>
                    </IconContext.Provider>
                </button>
            </div>

            {/* md */}
        </InfoCard>
    );
}
ClubTransferCard.propTypes = {
    edges: PropTypes.arrayOf(Object).isRequired,
};
