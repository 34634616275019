import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import useWindowSize from "../hooks/useWindowSize";
import * as d3 from "d3";
import GlobalContext from "../store/global-context";

const createForceLayout = function(nodes, edges, ref, ctx) {
    const leagues = [
        "PremierLeague",
        "Premier League 2",
        "Championship",
        "League One",
        "Bundesliga",
        "2. Bundesliga",
        "Eredivisie",
        "Serie A",
        "Serie B",
        "Ligue 1",
        "Ligue 2",
        "LaLiga",
        "LaLiga2",
        "Liga Portugal",
        "Jupiler Pro League",
        "Challenger Pro League",
        "Without league",
        "Super League 1",
        "Süper Lig",
        "1.Division",
        "A-League Men",
        "Professional League",
        "MLS",
        "Série A",
    ];

    const leagueBackgroundScale = d3
        .scaleOrdinal()
        .domain(leagues)
        .range([
            "#ffffff",
            "#b5179e",
            "#9d4edd",
            "#dac3e8",
            "#e01e37",
            "#f6cacc",
            "#f56a00",
            "#014f86",
            "#a9d6e5",
            "#d4d700",
            "#eeef20",
            "#0a9396",
            "#e9d8a6",
            "#260701",
            "#ac1c1e",
            "#dd2c2f",
            "#b2b0b0",
            "#219ebc",
            "#ef3c2d",
            "#bbdefb",
            "#0c3e5e",
            "#d08c60",
            "#71093b",
            "#0d7503",
        ]);

    const leagueBorderScale = d3
        .scaleOrdinal()
        .domain(leagues)
        .range([
            "#f72585",
            "#b5179e",
            "#9d4edd",
            "#dac3e8",
            "#e01e37",
            "#f6cacc",
            "#f56a00",
            "#014f86",
            "#a9d6e5",
            "#d4d700",
            "#eeef20",
            "#0a9396",
            "#e9d8a6",
            "#260701",
            "#ac1c1e",
            "#dd2c2f",
            "#b2b0b0",
            "#219ebc",
            "#ef3c2d",
            "#bbdefb",
            "#0c3e5e",
            "#d08c60",
            "#71093b",
            "#0d7503",
        ]);

    const transferFeeScale = d3
        .scaleLinear()
        .domain(d3.extent(edges, (d) => d.transfer_fee))
        .range([1, 10]);

    const sizesArr = Array(24).fill(5);
    sizesArr[0] = 12;
    const leagueSizeScale = d3.scaleOrdinal().domain(leagues).range(sizesArr);

    const nodeHash = {};
    nodes.forEach((node) => {
        nodeHash[node.club] = node;
    });

    const reformattedEdges = [];
    edges.forEach((edge) => {
        const e = {};
        e.weight = transferFeeScale(parseInt(edge.transfer_fee));
        e.source = nodeHash[edge.club_left_name];
        e.target = nodeHash[edge.club_joined_name];
        e.loan_transfer = edge.loan_transfer === "True"
        reformattedEdges.push(e);
    });

    // tick function
    const forceTick = () => {
        d3.selectAll("line.link")
            .attr("x1", (d) => d.source.x)
            .attr("x2", (d) => d.target.x)
            .attr("y1", (d) => d.source.y)
            .attr("y2", (d) => d.target.y);

        d3.selectAll("g.node").attr(
            "transform",
            (d) => `translate(${d.x}, ${d.y})`
        );
    };

    // Selection
    // const selectedNode = null;

    // Link force
    const linkForce = d3.forceLink().strength((d) => d.weight * 0.1);
    const simulation = d3
        .forceSimulation()
        .force("charge", d3.forceManyBody().strength(-500))
        .force("x", d3.forceX(window.innerWidth / 2))
        .force("y", d3.forceY(window.innerHeight / 2))
        .force("center", d3.forceCenter().x(window.innerWidth / 2).y(window.innerHeight / 2))
        .force("link", linkForce)
        .nodes(nodes)
        .on("tick", forceTick);

    simulation.force("link").links(reformattedEdges);

    // Create chart
    const svg = d3
        .select(ref.current)
        .attr("viewBox", [0, 0, window.innerWidth, window.innerHeight])
        .attr("font-family", "sans-serif")
        .attr("font-size", 10)
        .style("display", "block");

    const g = svg.append("g").attr("id", "bbox");

    const links = g
        .selectAll("line.link")
        .data(
            reformattedEdges,
            (d) =>
                `${!!d.source ? d.source.club : ""}-${
                    !!d.target ? d.target.club : ""
                }`
        )
        .enter()
        .append("line")
        .attr("class", "link")
        .attr("data-source", (d) => d.source.club)
        .attr("data-target", (d) => d.target.club)
        .style("stroke", "#000000")
        .style("stroke-dasharray", d => d.loan_transfer ? "4" : "none")
        .style("opacity", 0.5)
        .style("stroke-width", (d) => d.weight);

    var nodeEnter = g
        .selectAll("g.node")
        .data(nodes, (d) => d.index)
        .enter()
        .append("g")
        .attr("id", (d) => `g${d.index}`)
        .attr("class", "node")
        .style("cursor", "pointer");

    nodeEnter
        .append("circle")
        .attr("r", (d) => leagueSizeScale(d.league))
        .attr("id", (d) => `circle${d.index}`)
        .attr("data-id", (d) => d.index)
        .style("fill", (d) => leagueBackgroundScale(d.league))
        .style("stroke-width", 3)
        .style("stroke", (d) => leagueBorderScale(d.league));

    nodeEnter
        .append("image")
        .attr("href", (d) => d.league === "Premier League" ? d.logo : "")
        .attr("height", 20)
        .attr("transform", "translate(-10,-10)")
        .attr("id", (d) => `image${d.index}`);

    nodeEnter
        .append("text")
        .style("text-anchor", "middle")
        .attr("y", (d) => leagueSizeScale(d.league) + 10)
        .attr("id", (d) => `text${d.index}`)
        .attr("data-id", (d) => d.index)
        .text((d) => d.club);

    // Add marker
    const createMarker = (color, name) => {
        var marker = svg
            .append("defs")
            .append("marker")
            .attr("id", (d) => `triangle-${name}`)
            .attr("refX", 18)
            .attr("refY", 6)
            .attr("markerUnits", "userSpaceOnUse")
            .attr("markerWidth", 12)
            .attr("markerHeight", 18)
            .attr("orient", "auto")
            .append("path")
            .attr("d", "M 0 0 12 6 0 12 3 6")
            .style("fill", color);
        return marker;
    };

    createMarker("#000000", "normal");
    createMarker("#B8B8B8", "faded");
    createMarker("#8ac926", "green");
    createMarker("#ff595e", "red");

    g.selectAll("line").attr("marker-end", "url(#triangle-normal)");

    // Create zoom
    const zoom = d3.zoom();
    zoom.on("zoom", (e) => {
      simulation.stop();
      g.attr("transform", e.transform);
      simulation.restart();
    });

    svg.call(zoom).call(zoom.transform, d3.zoomIdentity.translate(100, 50).scale(0.7));

    // Add hover
    nodeEnter.on("click", (d) => {

        // Set selected club in context
        ctx.setSelectedClub(d.target.__data__.club)

        // Highlight node
        nodeEnter
            .selectAll("circle")
            .style("fill", "#B8B8B8")
            .style("stroke", "#B8B8B8");
        nodeEnter.selectAll("text").style("fill", "#B8B8B8");
        nodeEnter.style("fill", "#B8B8B8");

        d3.selectAll(`circle#circle${d.target.__data__.index}`)
            .style("fill", "#ffea00")
            .style("stroke", (sd) =>
                leagueBorderScale(d.target.__data__.league)
            );
        d3.selectAll("image").attr("opacity", "0.1");
        d3.selectAll(`text#text${d.target.__data__.index}`).style(
            "fill",
            "#000000"
        );
        d3.selectAll(`image#image${d.target.__data__.index}`).attr(
            "opacity",
            "1"
        );
        // Highlight links
        links
            .style("stroke", function (link_d) {
                // Outgoing colour and incoming colour
                if (link_d.source.index === d.target.__data__.index) {
                    d3.select(`circle#circle${link_d.target.index}`)
                        .style("fill", "#8ac926")
                        .style("stroke", (d) =>
                            leagueBorderScale(link_d.target.league)
                        );
                    d3.selectAll(`image#image${link_d.target.index}`).attr(
                        "opacity",
                        "1"
                    );
                    d3.selectAll(`text#text${link_d.target.index}`).style(
                        "fill",
                        "#000000"
                    );
                    return "#8ac926";
                } else if (
                    link_d.target.index === d.target.__data__.index
                ) {
                    d3.select(`circle#circle${link_d.source.index}`)
                        .style("fill", "#ff595e")
                        .style("stroke", (d) =>
                            leagueBorderScale(link_d.source.league)
                        );
                    d3.selectAll(`image#image${link_d.source.index}`).attr(
                        "opacity",
                        "1"
                    );
                    d3.selectAll(`text#text${link_d.source.index}`).style(
                        "fill",
                        "#000000"
                    );
                    return "#ff595e";
                } else {
                    return "#B8B8B8";
                }
            })
            .attr("marker-end", (link_d) => {
                // Outgoing colour and incoming colour
                if (link_d.source.index === d.target.__data__.index) {
                    return "url(#triangle-green)";
                } else if (
                    link_d.target.index === d.target.__data__.index
                ) {
                    return "url(#triangle-red)";
                } else {
                    return "url(#triangle-faded)";
                }
            })
            .attr("class", (link_d) =>
                link_d.source.index === d.target.__data__.index ||
                link_d.target.index === d.target.__data__.index
                    ? "link active"
                    : "link"
            );
    });

    // Backdrop clicking
    svg.on("click", (e) => {
        if (e.target.nodeName === "svg") {

          ctx.setSelectedClub(null);

          // Reset node
          nodeEnter
              .selectAll("circle")
              .style("fill", (d) => leagueBackgroundScale(d.league))
              .style("stroke", (d) => leagueBorderScale(d.league));
          nodeEnter.selectAll("text").style("fill", "#000000");

          d3.selectAll("image").attr("opacity", "1");

          links
              .style("stroke", "#000000")
              .style("opacity", 0.5)
              .style("stroke-width", (d) => d.weight)
              .attr("class", "link")
              .attr("marker-end", "url(#triangle-normal)");
      }
    });

    return svg.node();
};

export default function Chart(props) {

    const ctx = useContext(GlobalContext);

    const { nodes, edges } = props;

    const ref = useRef();
    const windowSize = useWindowSize()

    useEffect(() => {
      if (!!nodes && !!edges) {
        createForceLayout(nodes, edges, ref, ctx);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodes, edges])

    return <svg ref={ref} height={windowSize.height} width={windowSize.width} />;
}
Chart.propTypes = {
    nodes: PropTypes.arrayOf(Object),
    edges: PropTypes.arrayOf(Object),
};
