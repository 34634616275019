import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import InfoCard from "../Components/InfoCard";
import * as d3 from "d3";
import GlobalContext from "../store/global-context";

export default function StatisticsCard(props) {

    const ctx = useContext(GlobalContext);

    const [currentTab, setCurrentTab] = useState(0);

    const titles = ["Top Transfer In", "Top Transfer Out", "Top Spend Transfer In", "Top Gain Transfer Out"];

    var topTransferIn
    var topTransferOut;
    var topSpender;
    var topEarners;

    useEffect(() => {
      setCurrentTab(0);
    }, [])


    // Top Transfers IN
    if (props.edges.length > 0) {
      const allTransfersInMap = new Map(
          [
              ...d3
                  .rollup(
                      props.edges,
                      (v) => v.length,
                      (d) => d.club_joined_name
                  )
                  .entries(),
          ].sort((a, b) => b[1] - a[1])
      );
      topTransferIn = [...allTransfersInMap.entries()].slice(0, 5)
  
      // Top Transfers OUT
      const allTransfersOutMap = new Map(
          [
              ...d3
                  .rollup(
                      props.edges,
                      (v) => v.length,
                      (d) => d.club_left_name
                  )
                  .entries(),
          ].sort((a, b) => b[1] - a[1])
      );
      topTransferOut = [...allTransfersOutMap.entries()].slice(0, 5)

      // Top Spender
      const allSpenderMap = new Map(
        [
            ...d3
                .rollup(
                    props.edges,
                    (v) => d3.sum(v, d => +d.transfer_fee),
                    (d) => d.club_joined_name
                )
                .entries(),
        ].sort((a, b) => b[1] - a[1])
    );
    topSpender = [...allSpenderMap.entries()].slice(0, 5)

    // Top Earners
    const allEarnersMap = new Map(
        [
            ...d3
                .rollup(
                    props.edges,
                    (v) => d3.sum(v, d => +d.transfer_fee),
                    (d) => d.club_left_name
                )
                .entries(),
        ].sort((a, b) => b[1] - a[1])
    );
    topEarners = [...allEarnersMap.entries()].slice(0, 5)

    }

    return (
        <InfoCard>
            <h2 className="m-0 p-0 py-2 whitespace-nowrap font-bold text-slate-600">
                {titles[currentTab]}
            </h2>
            <ul className={`${currentTab === 0 ? "block": "hidden" } flex flex-row space-x-3`}>
                {!!topTransferIn && topTransferIn.map((c) => 
                <li key={c[0]}>
                  <button>
                        <img
                            className="w-8 h-8 m-0 p-0 rounded-full"
                            src={!!ctx.clubMapping.get(c[0]) ? ctx.clubMapping.get(c[0])[0].logo : ""}
                            alt="Rounded avatar"
                        />
                        <span>{c[1]}</span>
                  </button>
                </li>
                )}
            </ul>
            <ul className={`${currentTab === 1 ? "block": "hidden" } flex flex-row space-x-3`}>
                {!!topTransferOut && topTransferOut.map((c) => 
                <li key={c[0]}>
                  <button>
                        <img
                            className="w-8 h-8 m-0 p-0 rounded-full"
                            src={!!ctx.clubMapping.get(c[0]) ? ctx.clubMapping.get(c[0])[0].logo : ""}
                            alt={c[0]}
                        />
                        <span>{c[1]}</span>
                  </button>
                </li>
                )}
            </ul>
            <ul className={`${currentTab === 2 ? "block": "hidden" } flex flex-row space-x-3`}>
                {!!topSpender && topSpender.map((c) => 
                <li key={c[0]}>
                  <button>
                        <img
                            className="w-8 h-8 m-0 p-0 rounded-full"
                            src={!!ctx.clubMapping.get(c[0]) ? ctx.clubMapping.get(c[0])[0].logo : ""}
                            alt={c[0]}
                        />
                        <span>€{Math.trunc(c[1] / 1e6)}</span>
                  </button>
                </li>
                )}
            </ul>
            <ul className={`${currentTab === 3 ? "block": "hidden" } flex flex-row space-x-3`}>
                {!!topEarners && topEarners.map((c) => 
                <li key={c[0]}>
                  <button>
                        <img
                            className="w-8 h-8 m-0 p-0 rounded-full"
                            src={!!ctx.clubMapping.get(c[0]) ? ctx.clubMapping.get(c[0])[0].logo : ""}
                            alt={c[0]}
                        />
                        <span>€{Math.trunc(c[1] / 1e6)}</span>
                  </button>
                </li>
                )}
            </ul>
            {/* Slider */}
            <div className="flex flex-row justify-center mt-4 space-x-3">
                <button
                    type="button"
                    className={`${currentTab === 0 ? "bg-slate-500" : "bg-slate-100"} w-3 h-3 rounded-full`}
                    aria-current="false"
                    aria-label="Top Transfer In"
                ></button>
                <button
                    type="button"
                    className={`${currentTab === 1 ? "bg-slate-500" : "bg-slate-100"} w-3 h-3 rounded-full`}
                    aria-current="false"
                    aria-label="Top Transfer Out"
                ></button>
                <button
                    type="button"
                    className={`${currentTab === 2 ? "bg-slate-500" : "bg-slate-100"} w-3 h-3 rounded-full`}
                    aria-current="false"
                    aria-label="Top Spending"
                ></button>
                <button
                    type="button"
                    className={`${currentTab === 3 ? "bg-slate-500" : "bg-slate-100"} w-3 h-3 rounded-full`}
                    aria-current="false"
                    aria-label="Top Spending"
                ></button>
            </div>
            <button
                type="button"
                className="absolute top-1/2 -translate-y-1/2 -right-6 shadow-md text-white bg-slate-700 hover:bg-slate-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2"
                onClick={() => setCurrentTab((currentTab + 1) % 4)}
            >
                <svg
                    aria-hidden="true"
                    className="w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    ></path>
                </svg>
                <span className="sr-only">Next Page</span>
            </button>
            
        </InfoCard>
    );
}
StatisticsCard.propTypes = {
    edges: PropTypes.arrayOf(Object),
};
